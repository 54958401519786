// const HOST = process.env.NODE_ENV === 'development' ? '//test.zhike.help.360.cn' : '//zhike.help.360.cn'
import Cookies from "js-cookie";

let domain = ''
switch (process.env.NODE_ENV) {
  case "development":
    // domain = '//test.zhike.help.360.cn';
    // domain = '//10.16.49.85:8368';
    domain = '//test.zhike.help.360.cn';
    break;
  case "none":
    domain = '//test.zhike.help.360.cn';
    break;
  default:
    domain = '//zhike.help.360.cn';
}
const HOST = domain;
// const HOST = '//dev.test.help.360.cn:8369'||domain;
// const HOST = '//10.16.49.85:18369'||domain;

import {generateTag, getUTag, generateWid, getEncryptInfo, operateMultiId} from './util/utils';

const randomTag = getUTag();
const queryString = require('query-string');
const {rid = '6b4ac81a-0113-493a-aa8c-2a301549242b', sid = '', launch_id = '', code = '', appid = '',run_mode = ""} = queryString.parse(location.search);


/**
 * 请求客服信息
 */
export function getInfo(params) {
  generateWid();
  let data = {
    rid: rid,
    sid: sid, // 用于内网用户登录
    ...params
  };
  const info = getEncryptInfo();
  data = {...data, ...info};
  return new Promise(resolve => {
    $.ajax({
      url: `${HOST}/api/v1/robotInfo`,
      method: 'get',
      data: data,
      dataType: 'jsonp',
      success: data => resolve(data)
    })
  })
}

/**
 * 获取答案
 * @param {string} game 游戏问题库名称
 * @param {string} question 问题
 */
export function getAnswer(params,version) {
  // generateWid();
  let qus = ''
  if (params.game === '通用' || params.game === '' || params.game === undefined) { // 通用库不需要拼接
    qus = params.question
  } else {
    qus = `${params.question}_${params.game}`
  }

  const sendData = {
    rid: rid,
    run_mode,
    refund:params.refund,
    tag: params.account || randomTag || (Cookies.get('uTag')),
    question: qus,
    // multi_turn_id:`${operateMultiId({refresh:true}).id}`,
    dialogue_id:`${operateMultiId({refresh:true}).id}`,
    wid: `${generateWid()}`,
    launch_id,
    send_message_type: params.send_message_type
    // aid: 'a218fce2-6992-46a2-9818-0cdebf8ae3f4'
  };
  params.intent_id && (sendData.intent_id = params.intent_id);
  params.select_id && (sendData.select_id = params.select_id);
  code && (sendData.code = code);
  appid && (sendData.appid = appid);

  return new Promise((resolve,reject) => {
    $.ajax({
      url: `${HOST}/api/v1/submitQusV2`,
      method: 'get',
      data: sendData,
      dataType: 'jsonp',
      timeout:20000,
      success: data => resolve(data),
      error:e=>reject(e),
    })
  })
}

/**
 * 点赞
 * @param {number} reply 点赞|非点赞  ->  1|非1
 * @param {string} id 答案id
 */
export function replyAnswer({id, reply}) {
  // generateWid();
  return new Promise(resolve => {
    $.ajax({
      url: `${HOST}/api/v1/reply`,
      method: 'get',
      data: {
        rid,
        id,
        reply,
        wid: `${generateWid()}`,
        launch_id
      },
      dataType: 'jsonp',
      success: data => resolve(data)
    })
  })
}

export function matchGame({keyword, pkey}) {
  generateWid();
  return new Promise(resolve => {
    $.ajax({
      url: `${HOST}/api/v1/game`,
      method: 'get',
      data: {
        keyword,
        pkey,
      },
      dataType: 'jsonp',
      success: data => resolve(data)
    })
  })
}

