import xssFilters from "xss";
import xssOptions from "../static/xssFiltersOptions";
import {getAnswer} from "../static/api";
import {operateMultiId, uuid} from "../static/util/utils";
import userCenterControl from '../userCenter/processControl';


export function convertOrderList(data={}){
  const {select_type,list,type } = data || {};
  let result = [];
  if(select_type === 'order' && Array.isArray(list)){
    result = list.map(item=>{
      return {
        id:item.order_id,
        question_id:item.order_id,
        question:`会员名称：${item.member_desc}<br/>商品名称：${item.custom_desc}<br/>产品名称：${item.product} <br/>订单号：${item.order_id}<br/>订单时间：${item.order_pay_time}<br/>`
      }
    })
  }else if(type === 'dialogue_faq' && Array.isArray(list)){
    result = list.map(item=>{
      // if(typeof item === 'string'){
      //   return item;
      // }
      return {
        id:item.question_id,
        question_id:item.question_id,
        question:item.question,
      }
    })
  }else if(Array.isArray(list)){
    result = list.map(item=>{
      if(typeof item === 'string'){
        return item;
      }
      return {
        id:item.id || item.question_id,
        question_id:item.id || item.question_id,
        question:item.content || item.question,
      }
    })
  }else if(Array.isArray(data)){
    result = data.map(item=>{
      if(typeof item === 'string'){
        return item;
      }
      return {
        id:item.id || item.question_id,
        question_id:item.id || item.question_id,
        question:item.content || item.question,
      }
    })
  }
  return result;
}

//多轮对话进程控制
async function processControl({e, id, reducer, info, t, connectIM, resp:rResp, expand,loginControl}) {
  const _this = this;
  const _args = arguments;
  if (rResp && e !== 'login') {
    if(rResp.data?.need_login){
      if (!loginControl) {
        console.log('not loginControl');
        operateMultiId({
          clear: true
        })
        return;
      }
      //需要登录
      let res = await loginControl.login();
      if(!res){
        console.log('not login');
        return;
      }
      _args && _args[0] && _args[0].e !== undefined && (_args[0].e = 'login');
      return e != 'login' && processControl.apply(_this,_args);
    }
    reducer({
      type: 'dealResponseWithList', param: {
        content: rResp.data.answer || noAnswer,
        contentList: rResp.data.ext_answer,
        list: convertOrderList(rResp.data?.select_list || rResp.data?.dialogue_faq_list || rResp.data?.dialogue_func_list || rResp.data?.dialogue_collect_single  || (rResp.data?.dialogue_select )),
        id: rResp.data.id || '',
        commons: info.kfInfo.guess,
        msgId: uuid(),
      }
    });
    return;
  }
  // console.log(arguments);
  const stringToSend = xssFilters(e.trim(), xssOptions);
  if (!stringToSend) {
    return
  }
  let param = {game: '', question: ''} // 请求答案的参数
  param.question = stringToSend;
  id && (param.select_id = id);
  if(e !== 'login'){
    // 显示输入
    reducer({type: 'waitQuestion', param: stringToSend})
  }else{
    param.question = 'login';
  }

  //如果存在域账号则带入(360+适配)
  if (info && info.uInfo && info.uInfo.account) {
    param.account = info.uInfo.account;
  }
  let method = getAnswer;
  // 获取问题的答案
  method(param,'V2').then(async resp => {
    console.log(resp);
    try {
      if (parseInt(resp.errno) !== 0) {
        reducer({
          type: 'init', param: {
            content: resp.errmsg || t('wrong')
          }
        })
        null[0];
      }

      if(resp.data?.need_login){
        if (!loginControl) {
          console.log('not loginControl');
          operateMultiId({
            clear: true
          })
          return;
        }
        //需要登录
        let res = await loginControl.login();
        if(!res){
          console.log('not login');
          return;
        }
        _args && _args[0] && _args[0].e && (_args[0].e = 'login');
        return e != 'login' && processControl.apply(_this,_args);
      }

      reducer({
        type: 'dealResponseWithList', param: {
          content: resp.data.answer || "",
          contentList: resp.data.ext_answer,
          list: convertOrderList(resp.data?.select_list || resp.data?.dialogue_faq_list || resp.data?.dialogue_func_list || resp.data?.dialogue_collect_single || (resp.data?.dialogue_select)),
          id: resp.data.id || '',
          msgId: uuid(),
        }
      });

      //存在接待组id 转人工
      if (resp.data?.reception_id) {
        connectIM(false, null, [resp.data?.reception_id], 1);
        null[0];
      }

      if (resp?.data?.dialogue_id) {
        //如果有多轮对话id则保存
        operateMultiId({
          id: resp.data.dialogue_id, expand: {is_big_vip: resp.data?.is_big_vip}
        });
        return;
      }

      if (!resp.data.dialogue_id) {
        null[0];
      }



    } catch (e) {
      debugger
      operateMultiId({
        clear: true
      })
    }
  }).catch(e => {
    reducer({
      type: 'init', param: {
        content: t('wrong')
      }
    })
    //退出多轮对话
    operateMultiId({
      clear: true
    })
  });

}

const control = new Proxy(processControl, {
  async apply(target, thisArg, argArray) {
    console.log(argArray[0]);
    if (argArray[0].msgId && argArray[0].reducer) {
      //如果为点选列表，则disable掉列表
      argArray[0].reducer({
        type: 'update', msgId:argArray[0].msgId, callback: (item) => {
          item.disable = true;
          return item
        }
      })
    }
    // debugger;
    //如果为会员中心意图
    if (argArray && argArray[0] && argArray[0]?.expand?.is_big_vip) {
      userCenterControl.apply(thisArg, argArray)
    } else {
      target.apply(thisArg, argArray);
    }
  }
})

export default control;